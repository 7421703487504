<wre-toolkit-modal-popup [title]="title" [preventDefaultClose]="true" (click)="resetErrors()"
	(closeClicked)="confirmClose()">
	<wre-toolkit-loader *ngIf="isLoaderVisible"></wre-toolkit-loader>

	<div class="pb-3" *ngIf="conflictName || errorMessage">
		<div class="wre-form-error">
			<i class="fas fa-exclamation pe-2"></i>
			<ng-container *ngIf="conflictName; else errorMessageBlock">
				A report with the name&nbsp;<span class="font-weight-medium">{{ conflictName }}</span>&nbsp;already
				exists in the system.
			</ng-container>
			<ng-template #errorMessageBlock>{{ errorMessage }}</ng-template>
			<i class="far fa-times ms-auto" (click)="resetErrors()"></i>
		</div>
	</div>

	<wre-toolkit-wizard #wizard linear [hidden]="isLoaderVisible">

		<cdk-step label="Details" [completed]="reportDetailsStep?.nextStepButtonEnabled">
			<div class="wre-wizard-inner-container position-relative" id='report-details-step'>
				<wre-report-wizard-details #reportDetailsStep [newReportForm]="newReportForm" [existingReport]="report">
				</wre-report-wizard-details>
			</div>
		</cdk-step>

		<cdk-step label="Configuration" [completed]="newReportForm.valid">
			<div class="wre-wizard-inner-container position-relative" id='report-config-step'>
				<wre-report-wizard-config-non-powerbi *ngIf="+newReportForm.get('type').value !== ReportType.PowerBI"
					[newReportForm]="newReportForm"></wre-report-wizard-config-non-powerbi>

				<wre-report-wizard-config-powerbi *ngIf="+newReportForm.get('type').value === ReportType.PowerBI"
					[newReportForm]="newReportForm"></wre-report-wizard-config-powerbi>
			</div>
		</cdk-step>

		<cdk-step label="Security Groups" [completed]="newReportForm.valid">
			<div class="wre-wizard-inner-container" id='report-security-step'>
				<ng-container *ngIf="+newReportForm.get('type').value === ReportType.PowerBI &&
												newReportForm.get('powerBiConfig.rowLevelSecurity').value; else securityRolesBlock">
					<wre-reports-rls-role-form [rlsRolesForm]="newReportForm.get('powerBiConfig.rlsRoles')">
					</wre-reports-rls-role-form>
					<wre-reports-rls-roles-list [rlsRolesForm]="newReportForm.get('powerBiConfig.rlsRoles')">
					</wre-reports-rls-roles-list>
				</ng-container>

				<ng-template #securityRolesBlock>
					<wre-security-role-form [securityRolesForm]="newReportForm.get('permissions')"
						[application]="reportingApplication">
					</wre-security-role-form>
				</ng-template>

			</div>
		</cdk-step>

	</wre-toolkit-wizard>

	<div footer>
		<div class="row">
			<div class="col-sm-12 d-flex justify-content-end">
				<button *ngIf="!!showBackButton" type="button" class="btn wre-btn btn-secondary ms-3"
					[disabled]="isLoaderVisible" (click)="backStep()">
					Back
				</button>

				<button type="button" [disabled]="!isCompleted || isLoaderVisible" class="btn wre-btn btn-primary ms-3"
					(click)="executeStep()">
					{{ nextButtonText }}
				</button>
			</div>
		</div>
	</div>

</wre-toolkit-modal-popup>
