<label class="form-control-label">Selected Roles</label>
<div class="card">
  <div class="card-body p-0">
    <div class="role-entry" *ngFor="let role of rlsRoles; index as idx">
      <i class="far fa-file-chart-pie"></i>
      &nbsp;
      {{ role.name }}
      &nbsp;
      <span class="subtle-text">- - -</span>
      &nbsp;
      <i class="far fa-lock"></i>
      &nbsp;
      {{ role.securityRole.name }}

      <div class="float-right px-2 delete-button" title="Delete role" (click)="deleteRole(idx)">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>
