import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReportFormService } from '../report-form.service';
import { Applications, RLSRole } from '../../../../models/admin';

@Component({
  selector: 'wre-reports-rls-role-form',
  templateUrl: './reports-rls-role-form.component.html',
  styleUrls: ['./reports-rls-role-form.component.sass']
})
export class ReportsRlsRoleFormComponent {

  @Input() rlsRolesForm: UntypedFormControl;
  application = Applications.WreReporting;

  get selectedRlsRoles(): RLSRole[] {
    return this.rlsRolesForm ? this.rlsRolesForm.value as RLSRole[] : [];
  }

  get addDisabled(): boolean {
    return this.rlsRoleFormGroup.invalid || this.rlsRoleExists();
  }

  rlsRoleFormGroup: UntypedFormGroup;

  constructor(private reportFormService: ReportFormService) {
    this.rlsRoleFormGroup = this.reportFormService.createRLSRoleFormGroup();
  }

  rlsRoleExists(): boolean {
    return this.selectedRlsRoles.some(r => {
      const newRole = this.currentRlsRoleFormValue();
      return r.name.trim().toLocaleLowerCase() === newRole.name.toLocaleLowerCase() &&
        r.securityRole.guid === newRole.securityRole.guid;
    });
  }

  onAddRole() {
    const newRole = this.currentRlsRoleFormValue();
    this.selectedRlsRoles.push(newRole);
    this.rlsRoleFormGroup.reset();
  }

  currentRlsRoleFormValue(): RLSRole {
    const newRole = this.rlsRoleFormGroup.value as RLSRole;
    newRole.name = newRole.name.trim();
    return newRole;
  }

}
